<template>
  <div>
    <v-row>
      <v-col xl="6" lg="6" md="6" sm="6" cols="12">
        <v-text-field
          type="text"
          :label="search_title_text"
          v-model="search_title"
          v-on:keyup.enter="searchValue()"
          @click:append="searchValue()"
          :append-icon="magnify_icon"
          outlined
          hide-details
        ></v-text-field>
      </v-col>
      <v-col xl="6" lg="6" md="6" sm="6" cols="12">
        <v-dialog ref="dialog" v-model="modal" :return-value.sync="filter_date" persistent width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filter_date"
              :label="filter_date_text"
              :append-icon="calendar_icon"
              readonly
              hide-details
              v-bind="attrs"
              outlined
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter_date" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="cancelFilterDate"> Cancel </v-btn>
            <v-btn text color="primary" @click="filterDate(filter_date)"> OK </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <CorrectDataTable
          :table-headers="payroll_header"
          :table-data="payroll_list"
          :page="tablePage"
          :page-limit="tablePageLimit"
          :page-limit-options="tablePageLimitOptions"
          :item-total="total_items"
          :is-loading="load_table"
          :enable-search="false"
          :enable-filter="false"
          :enable-date-range="false"
          :enable-clear-all="false"
          :disable-pagination="load_table"
          :disable-sort="true"
          @options-update="onTableChange"
          :actionViewRouteLink="actionViewRouteLink"
        ></CorrectDataTable>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CorrectDataTable from '@/components/CorrectDataTable.vue'
import { getData } from '@/api'
import { mdiCalendar, mdiMagnify } from '@mdi/js'
import { globalFunc } from '@/global'
export default {
  name: 'Payroll',
  components: {
    CorrectDataTable,
  },
  data: () => ({
    calendar_icon: mdiCalendar,
    magnify_icon: mdiMagnify,
    payroll_header: [],
    payroll_list: [],
    load_table: true,
    tablePage: 1,
    tablePageLimit: 10,
    tablePageLimitOptions: [10, 20, 50],
    total_items: 0,
    modal: false,
    filter_date: '',
    search_title: '',
    search_title_text: '',
    filter_date_text: '',
    actionViewRouteLink: 'PayrollDetail',
  }),
  methods: {
    initializeHeader() {
      this.payroll_header = [
        {
          text: this.$t('job_post.job_id'),
          value: 'job_id',
        },
        {
          text: this.$t('worker.worker_id'),
          value: 'worker_id',
        },
        {
          text: this.$t('job_post.worker_first_name'),
          value: 'worker_first_name',
        },
        {
          text: this.$t('job_post.worker_last_name'),
          value: 'worker_last_name',
        },
        {
          text: `${this.$t('job_post.agreement')} ${this.$t('job_post.create_date')}`,
          value: 'agreement_create_date',
        },
        {
          text: `${this.$t('job_post.timesheet')} ${this.$t('job_post.create_date')}`,
          value: 'create_date',
        },
        {
          text: this.$t('job_post.complete_date'),
          value: 'complete_date',
        },
        {
          text: this.$t('job_post.timesheet_status'),
          value: 'timesheet_status',
        },
        {
          text: '',
          value: 'actionView',
        },
      ]
    },
    async initializeData() {
      // TODO: filter
      this.load_table = true
      try {
        let send_data = { page: this.tablePage - 1, limit: this.tablePageLimit }
        let params = new URLSearchParams()
        params.set('cms_get_time_sheet', JSON.stringify(send_data))
        let get_timesheet_data = await getData(params)
        this.$func.log('-----Get Timesheet Data-----')
        this.$func.log(get_timesheet_data)

        if (!Array.isArray(get_timesheet_data)) {
          this.total_items = get_timesheet_data.total
          let timesheet = get_timesheet_data.data

          let agreement_list = timesheet.map(obj => {
            return obj.agreement_id
          })

          let worker_id_list = timesheet.map(obj => {
            return obj.worker_id
          })

          params = new URLSearchParams()
          params.set('cms_get_agreement', JSON.stringify({ id_list: agreement_list }))
          let get_agreement_data = await getData(params)
          this.$func.log('-----Get Agreeement Data-----')
          this.$func.log(get_agreement_data)
          let agreement = get_agreement_data.data

          params = new URLSearchParams()
          params.set('cms_get_user', JSON.stringify({ user_id_list: worker_id_list }))
          let get_user_data = await getData(params)
          this.$func.log('-----Get User Data-----')
          this.$func.log(get_user_data)
          let user = get_user_data.data

          let temp = []
          for (let i = 0; i < timesheet.length; i++) {
            let temp_data = {
              id: timesheet[i].id,
              job_id: timesheet[i].job_id,
              worker_id: timesheet[i].worker_id,
              create_date: timesheet[i].create_date,
              complete_date: timesheet[i].approve_date,
              timesheet_status: timesheet[i].status,
            }

            let user_data = user.filter(obj => {
              return obj.id == timesheet[i].worker_id
            })

            if (user_data.length > 0) {
              temp_data.worker_first_name = user_data[0].first_name
              temp_data.worker_last_name = user_data[0].last_name
            }

            let agreement_data = timesheet.filter(obj => {
              return obj.agreement_id == agreement[i].id
            })

            if (agreement_data.length > 0) {
              temp_data.agreement_create_date = globalFunc.getDateStr(agreement_data[0].create_date)
            }

            temp.push(temp_data)
          }

          this.payroll_list = temp
        }
        this.load_table = false
      } catch (error) {
        this.$func.log(error)
        this.load_table = false
      }
    },
    onTableChange(options) {
      if (options.itemsPerPage !== this.tablePageLimit) {
        this.tablePage = 1 // reset to first page if view options changed
      } else {
        this.tablePage = options.page
      }
      if (options.sortBy.length > 0) {
        if (options.sortBy[0].indexOf('user_data.') === 0) {
          this.tableSortKey = options.sortBy[0].substring(10)
        } else {
          this.tableSortKey = options.sortBy[0]
        }
      }

      this.tablePageLimit = options.itemsPerPage

      if (this.filter_date.length != 0) {
        this.initializeData(this.filter_date)
      } else {
        this.initializeData()
      }
    },
    searchValue() {
      if (globalFunc.DataValid(this.search_title)) {
        //this.tablePage = 0
        // this.initializeData()
      }
    },
    filterDate(filter_date) {
      this.modal = false
      this.$refs.dialog.save(filter_date)
      //this.tablePage = 0
      //  this.initializeData()
    },
    cancelFilterDate() {
      this.filter_date = ''
      this.modal = false
      this.$refs.dialog.save(this.filter_date)
      //this.tablePage = 0
      //  this.initializeData()
    },
  },
  async created() {
    const fetchAllow = await this.checkToken()
    if (fetchAllow) {
      this.search_title_text = `${this.$t('search.search')} ${this.$t('search.worker_name')} or ${this.$t(
        'search.tel',
      )} or ${this.$t('worker.email')}`
      this.filter_date_text = `${this.$t('job_post.timesheet')} ${this.$t('job_post.complete_date')}`

      this.$func.log(this.search_title_text)
      this.initializeHeader()
      this.initializeData()
    }
  },
}
</script>